<template>
  <div class="footer">
    <div class="footer_one">
      <div class="code">
        <img src="../assets/xcx.jpg" alt="">
        大洋拍卖网小程序
      </div>
      <div class="code">
        <img src="../assets/gzh.jpg" alt="">
        大洋拍卖网公众号
      </div>
      <div>
        <div>联系我们</div>
        <div style="margin-top:1rem">客服电话：13304773061</div>
        <div>客服邮箱：13304773061@163.com</div>
        <div>办公时间：09:00-17:00</div>
        <div>办公地址：内蒙古自治区鄂尔多斯市东胜区东环路7号宏源一品E座2015室</div>
      </div>
      <div v-for="item in list" :key="item.id">
        <div>{{ item.name }}</div>
        <div class="cursor" @click="go(item.id, val.id)" :style="key === 0 ? 'margin-top:1rem' : ''"
          v-for="(val, key) in item.son" :key="val.id">{{ val.name }}</div>
      </div>

    </div>
    <div class="footer_two">蒙ICP备2023000572号@版权所属大洋拍卖网</div>
  </div>
</template>

<script>
  import {
    getHelpCate
  } from '@/api/other'

  export default {
    name: 'Footer',
    data() {
      return {
        list: []
      }
    },
    mounted() {
      this.getHelpCate()
    },
    methods: {
      getHelpCate() {
        getHelpCate().then(res => {
          this.list = res.data.data
        })
      },
      go(pid, id) {
        localStorage.setItem('cli', 6)
        this.$router.push('./otherInfoTwo?id=' + id + '&pid=' + pid)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/css/Footer.scss';
</style>
