<template>
  <div class="header">
    <div class="image"><img src="../assets/logo.png" alt=""></div>
    <div class="crux">
      <div class="search">
        <input type="text" class="search_input" v-model="keyword" @keyup.enter.native="inputCli">
        <div class="search_btn" @click="inputCli">
          <div class="btn_img">
            <img src="../assets/search.png" alt="">
          </div>
        </div>
      </div>
      <div class="crux_item">
        <p class="cursor" @click="cateCli('/bidding?cate_id=')">全部</p>
        <p class="cursor" v-for="item in cate" :key="item.id" @click="cateCli('/bidding?cate_id='+item.id)"> |
          {{ item.name }}
        </p>
      </div>
    </div>
  </div>

  <div class="nav">
    <div :class="[{nav_item_true: cli == 0}, 'nav_item cursor']" @click="goPath('/index', 0)">
      <div class="item_img">
        <img src="../assets/index.png" v-if="cli != 0" alt="">
        <img src="../assets/index_true.png" v-else alt="">
      </div>
      <div class="item_title">首页</div>
    </div>
    <div :class="[{nav_item_true: cli == 1}, 'nav_item cursor']" @click="goPath('/noticeList', 1)">
      <div class="item_img">
        <img src="../assets/news.png" v-if="cli != 1" alt="">
        <img src="../assets/news_true.png" v-else alt="">
      </div>
      <div class="item_title">拍卖公告</div>
    </div>
    <div :class="[{nav_item_true: cli == 2}, 'nav_item cursor']" @click="goPath('/bidding',2)">
      <div class="item_img">
        <img src="../assets/hui.png" v-if="cli != 2" alt="">
        <img src="../assets/hui_tue.png" v-else alt="">
      </div>
      <div class="item_title">拍卖会</div>
    </div>
    <div :class="[{nav_item_true: cli == 3}, 'nav_item cursor']" @click="goPath('/biddingTrue',3)">
      <div class="item_img">
        <img src="../assets/true.png" v-if="cli != 3" alt="">
        <img src="../assets/true_true.png" v-else alt="">
      </div>
      <div class="item_title">成交案例</div>
    </div>
    <div :class="[{nav_item_true: cli == 4}, 'nav_item cursor']" @click="goPath('/newsList',4)">
      <div class="item_img">
        <img src="../assets/zhongxin.png" v-if="cli != 4" alt="">
        <img src="../assets/zhongxin_true.png" v-else alt="">
      </div>
      <div class="item_title">资讯中心</div>
    </div>
    <div :class="[{nav_item_true: cli == 5}, 'nav_item cursor']" @click="goPath('/attract',5)">
      <div class="item_img">
        <img src="../assets/zhlaoshang.png" v-if="cli != 5" alt="">
        <img src="../assets/zhlaoshang_true.png" v-else alt="">
      </div>
      <div class="item_title">资产招商</div>
    </div>
    <div :class="[{nav_item_true: cli == 6}, 'nav_item cursor']" @click="goPath('/otherInfoTwo',6)">
      <div class="item_img">
        <img src="../assets/zhongxin.png" v-if="cli != 6" alt="">
        <img src="../assets/zhongxin_true.png" v-else alt="">
      </div>
      <div class="item_title">帮助中心</div>
    </div>
    <div class="nav_item cursor" v-if="token === ''">
      <div class="item_img"><img src="../assets/login.png" alt=""></div>
      <div class="item_title">
        <span @click="goPath('/Login')">登录</span> / <span @click="goPath('/register')">注册</span>
      </div>
    </div>

    <div class="nav_item cursor" v-if="token !== ''">
      <!--      <div class="item_img"><img src="../assets/login.png" alt=""></div>-->
      <div class="item_title">
        <text class="item_title_nickname" @click="goPath('/User')">{{ nickname }}</text> /
        <text @click="loginOut">退出</text>
      </div>
    </div>

  </div>

</template>

<script>
  import {
    checkToken,
    getBiddingCate
  } from '@/api/index'
  import {
    loginOut
  } from '@/api/user'

  export default {
    name: 'Header',
    data() {
      return {
        cli: 99,
        token: '',
        cate: [],
        keyword: '',
        nickname: ''
      }
    },
    mounted() {
      const _this = this
      const token = localStorage.getItem('token')

      if (token !== '' && token !== null) {
        checkToken().then(res => {
          if (res.code === 401) {
            this.token = ''
          }
        })
        this.token = token
        this.nickname = localStorage.getItem('nickname')
      }

      if (this.$route.query.keyword != undefined) {
        this.keyword = this.$route.query.keyword
      }

      setTimeout(function() {
        _this.setCli()
      }, 200)

      this.getBiddingCate()
    },
    methods: {
      goPath(url, cli) {
        localStorage.setItem('cli', cli)
        this.$router.push(url)
      },
      getBiddingCate() {
        getBiddingCate({
          platform: 'pc'
        }).then(res => {
          this.cate = res.data.data
        })
      },
      cateCli(url) {
        if (this.$route.fullPath.split('?')[0] == '/bidding') {
          window.location.href = '/bidding?cate_id=' + url.split('=')[1]
        } else {
          this.goPath(url, 2)
        }
      },
      inputCli() {
        if (this.$route.fullPath.split('?')[0] == '/search') {
          window.location.href = '/search?keyword=' + this.keyword
        } else {
          this.goPath('/search?keyword=' + this.keyword)
        }
      },
      setCli() {
        this.cli = localStorage.getItem('cli')
        if (this.cli === undefined || this.cli === 'undefined') {
          this.cli = 0
        }
      },
      loginOut() {
        const _this = this
        this.$confirm('确认退出当前登录账户？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        }).then(res => {
          loginOut().then(res => {
            if (res.data.code === 1) {
              localStorage.setItem('token', '')
              localStorage.setItem('userInfo', '')
              localStorage.setItem('nickname', '')
              this.$message({
                title: '成功',
                message: '<h4>退出成功，请稍后</h4>',
                type: 'success',
                dangerouslyUseHTMLString: true,
              })
              setTimeout(function() {
                _this.$router.go(0)
              }, 1000)
            }
          })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  img {
    width: 100%;
    height: 100%;
  }

  @import '../assets/css/Header.scss';
</style>
